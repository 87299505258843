.bannerTabbar {
  height: 500px;
  overflow: hidden;
}
.bannerTabbar img {
  width: 100%;
}
.newsOmit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.content {
  max-width: 1060px;
  margin: 5px auto 50px auto;
  font-family: auto;
}
.content .title {
  text-align: center;
  font-size: 30px;
  font-family: cursive;
  font-weight: bold;
  margin-bottom: 40px;
}
.content .newsImg {
  overflow: hidden;
  margin: 30px auto;
  border-radius: 5px;
  max-width: 1000px;
  max-height: 600px;
}
.content .newsImg .img {
  display: block;
  margin: 0 auto;
}
.content .newsImg .alt-desc {
  font-size: 13px;
  color: #8d8d8d;
  margin: 3px 0 0 0;
  text-align: center;
}
.content .paragraph {
  font-size: 15px;
  font-family: monospace;
  line-height: 24px;
  text-indent: 32px;
}
.content .cen-align {
  text-align: center;
}
.Detail {
  font-size: 12px;
}
.Detail .tabbarTar {
  position: relative;
  margin-bottom: 2.17%;
}
.Detail .tabbarTar .bannerTabbar {
  width: 100%;
}
.Detail .tabbarTar .bannerTabbar img {
  width: 100%;
}
.Detail .wrapper {
  width: 104rem;
  text-align: center;
  margin: 5% auto 0;
}
.Detail .wrapper .contentFrame .title {
  font-size: 3.17rem;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #333333;
  line-height: 4.42rem;
  margin-bottom: 4rem;
}
.Detail .wrapper .contentFrame .title2 {
  font-size: 2rem;
  text-align: center;
}
.Detail .wrapper .contentFrame .text-center {
  font-size: 30px;
  line-height: 6.2rem;
  font-weight: 600;
}
.Detail .wrapper .contentFrame .content {
  font-size: 1.6rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-bottom: 2.33rem;
}
.Detail .wrapper .contentFrame .contentImg {
  text-align: center;
}
.Detail .wrapper .contentFrame .contentImg img {
  width: 50rem;
  margin-bottom: 2.33rem;
}
.Detail .wrapper .contentFrame .contentImg-bor {
  text-align: center;
}
.Detail .wrapper .contentFrame .contentImg-bor img {
  width: 90.8rem;
  margin-bottom: 2.33rem;
}
.Detail .wrapper .contentFrame .borderC {
  border: 3px solid #8d8d8d;
  margin: 3rem 0;
  padding: 1rem;
}
.Detail .wrapper .contentFrame .borderC .personal-resume {
  border: 6px dotted #8d8d8d;
  padding: 2rem;
  text-align: left;
}
.Detail .wrapper .contentFrame .borderC .personal-resume .resumeTitle {
  text-align: center;
  font-size: 3rem;
  margin: 3rem 0;
}
.Detail .wrapper .changeBtn {
  margin-top: 4.33rem;
  padding-bottom: 10rem;
  font-size: 1.33rem;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
}
.Detail .wrapper .changeBtn .previous {
  display: inline-block;
  width: 28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Detail .wrapper .changeBtn .previous span {
  cursor: pointer;
}
.Detail .wrapper .changeBtn .previous span:hover {
  color: #ff6700;
}
.Detail .wrapper .changeBtn .next {
  float: right;
  width: 28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Detail .wrapper .changeBtn .next span {
  cursor: pointer;
}
.Detail .wrapper .changeBtn .next span:hover {
  color: #ff6700;
}
